.lth-c-select {
	/*------------------------------------------------------- */

	--select-input-bg-color: var(--color-neutral-50);
	--select-input-text-color: var(--color-neutral-800);
	--select-input-border-width: var(--border-width-s);
	--select-input-border-color: var(--color-neutral-400);
	--select-input-border-radius: var(--border-radius-s);
	--select-input-inset-spacing: var(--space-inset-s);
	--select-input-inset-hspacing: var(--space-inline-s);

	--select-input-hover-border-color: var(--color-neutral-600);

	--select-input-placeholder-text-color: var(--color-neutral-500);
	--select-input-arrow-color: var(--color-neutral-800);

	--select-focus-border-color: var(--control-focus-border-color);
	--select-focus-border-width: var(--control-focus-border-width);
	--select-focus-shadow: var(--control-focus-shadow);

	/*------------------------------------------------------- */
}
