.lth-c-dropdown.platform-wix {
	.lth-c-dropdown__button {
		box-shadow: none;

		position: relative;
		display: flex;
		border: solid var(--border-width-s);
		border-radius: 6px;
		box-sizing: border-box;
		font-family: "HelveticaNeueW01-55Roma","HelveticaNeueW02-55Roma","HelveticaNeueW10-55Roma","Helvetica Neue","Helvetica","Arial","メイリオ, meiryo","ヒラギノ角ゴ pro w3","hiragino kaku gothic pro","sans-serif";
		font-family: var(--wsr-font-family, "HelveticaNeueW01-55Roma","HelveticaNeueW02-55Roma","HelveticaNeueW10-55Roma","Helvetica Neue","Helvetica","Arial","メイリオ, meiryo","ヒラギノ角ゴ pro w3","hiragino kaku gothic pro","sans-serif");
		border-color: #C1E4FE;
		background-color: #fff;
		color: #162D3D;

		&:focus {
			outline: none;
			border-color: #3899EC;
			box-shadow: 0 0 0 3px #AADBFC;
		}
	}

	.lth-c-dropdown__menu {
		border: 0;
		border-radius: 8px;
		box-sizing: border-box;
		box-shadow: 0 6px 6px 0 rgba(22,45,61,.06), 0 0 18px 0 rgba(22,45,61,.12);
		-webkit-font-smoothing: antialiased;
		font-size: 14px;
		line-height: 18px;
		color: #162d3d;
		background: #fff;
		top: calc(100% - 3px);
		
		&__item {
			min-height: 35px;
			font-size: 16px;
			font-family: var(--wsr-font-family, HelveticaNeueW01-55Roma, HelveticaNeueW02-55Roma, HelveticaNeueW10-55Roma),"Helvetica Neue","Helvetica","Arial","\30E1\30A4\30EA\30AA","meiryo","\30D2\30E9\30AE\30CE\89D2\30B4 pro w3","hiragino kaku gothic pro","sans-serif";
			color: #162d3d;
			line-height: 1.5;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			padding: 6px 20px 6px 24px;
			cursor: pointer;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;

			&:hover {
				background: #eaf7ff;
				color: #162d3d;
			}
		}
	}
}