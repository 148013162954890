/*------------------------------------------------------- */

/**
	used to generate the very common pattern of position absolute,
	with all edges stretched to the full size of the container;

	usage:
	@include lth-absolute-fullsize;
	will generate:
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

	or, with overrides:
	@include lth-absolute-fullsize($bottom: 50px, $right: 80px);
	will generate:
		position: absolute;
		top: 0;
		bottom: 50px;
		left: 0;
		right: 80px;
*/
@mixin lth-absolute-fullsize($top: 0, $right: 0, $left: 0, $bottom: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

/*
	a mixin that adds a suffix to the parent ruleset, as well as to the value token name.
	usage:

	call the mixin with:
	$parent: a parent selector, to which you want to add the suffix
	$rule: a valid css rule key
	$token: an existing token, to which you want to add the suffix
	$suffixes: a list of all the suffixes needed

	@include lth-parent-with-suffixes(
		$parent: .outer,
		$rule: , flex-direction
		$token: '--toggle-direction',
		$suffixes: (left right top)
	);

	- will produce:
	.outer--left .inner {
		flex-direction: var(--toggle-direction-left);
	}
	.outer--right .inner {
		flex-direction: var(--toggle-direction-right);
	}
	.outer--top .inner {
		flex-direction: var(--toggle-direction-top);
	}
*/
@mixin lth-parent-with-suffixes($parent, $rule, $token, $suffixes) {
	@each $suffix in $suffixes {
		#{$parent}--#{$suffix} & {
			#{$rule}: var(#{$token}-#{$suffix});
		}
	}
}
/*-------------------------------------------------------*/
