/*-------------------------------------------------------*/

p,.lth-body-regular {
	font-family: var(--typography-font-family-base);
	font-size: var(--typography-body-regular-size);
	line-height: var(--typography-body-regular-lineheight);
}

.lth-body-large {
	font-family: var(--typography-font-family-base);
	font-size: var(--typography-body-large-size);
	line-height: var(--typography-body-large-lineheight);
}

.lth-body-small {
	font-family: var(--typography-font-family-base);
	font-size: var(--typography-body-small-size);
	line-height: var(--typography-body-small-lineheight);
}

.lth-body-xsmall {
	font-family: var(--typography-font-family-base);
	font-size: var(--typography-body-xsmall-size);
	line-height: var(--typography-body-xsmall-lineheight);
}

/*-------------------------------------------------------*/