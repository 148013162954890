@import "./badge.tokens.scss", "../typography.scss";

.lth-c-badge {
	$BASE: &;
	@extend .lth-body-xsmall;

	display: inline-block;
	background-color: var(--badge-background);
	color: var(--badge-color);
	border-radius: var(--badge-border-radius-pill-size);
	padding: var(--badge-padding-pill-size);
	text-align: center;

	@each $variant in (primary common warning danger) {
		&--#{$variant} {
			--badge-background: var(--badge-background-#{$variant});
			--badge-color: var(--badge-text-color-#{$variant}, var(--badge-text-color-default));
		}
	}

	&.lth-is-disabled {
		--badge-background: var(--badge-background-disabled);
		--badge-color: var(--badge-text-color-disabled);
	}
}
