.lth-c-badge {
	--badge-background-common: var(--color-primary-200);
	--badge-background-primary: var(--color-primary-600);
	--badge-background-warning: var(--color-warning-200);
	--badge-background-danger: var(--color-error-200);
	--badge-background-disabled: var(--color-neutral-100);

	--badge-text-color-default: var(--color-neutral-50);
	--badge-text-color-common: var(--color-neutral-800);
	--badge-text-color-disabled: var(--color-neutral-400);

	--badge-padding-pill-size: 0 var(--space-inline-m);
	--badge-border-radius-pill-size: 100px;
}
